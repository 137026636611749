<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/pontos-captura" />
      </v-col>
    </v-row>

    <pontos-captura-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import pontosCaptura from '@/api/pontos-captura'

  export default {
    components: {
      PontosCapturaCampos: () => import('./PontosCapturaCampos'),
    },

    data () {
      return {
        carregando: false,
        validacaoFormulario: {},
      }
    },

    methods: {
      async salvar (ponto) {
        this.carregando = true
        this.validacaoFormulario = {}

        try {
          const resposta = await pontosCaptura.inserir(ponto)

          this.$router.push(
            '/pontos-captura/' + resposta.data.id + '/editar',
            () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }),
          )
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },
    },
  }
</script>
